@import '../../util/Constant/sass-variable-list.scss';

.map-zoom-mark-horizontal {
  &__bottom,
  &__top {
    background-color: $green--dark;
    position: relative;
  }

  &__top {
    height: 5px;
    border-bottom: 1px solid $black;
    border-radius: 5px 5px 0 0;
    top: 1px;
  }

  &__bottom {
    height: 5px;
    border-radius: 0 0 5px 5px;
    border-top: 1px solid $black;
    top: -1px;
  }

  &__left-line,
  &__right-line {
    background-color: $blue-green--medium;
    height: 77.3vh;
    top: 4px;
    position: absolute;
    width: 0.1%;
    z-index: $step-forward;
  }

  &__left-line {
    left: 0;
  }

  &__right-line {
    right: 0;
  }
}
