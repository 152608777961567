.histogram-bar {
  display: flex;
  justify-content: center;
  position: absolute;

  &:hover {
    .histogram-bar-count,
    .histogram-bar-label {
      display: flex;
    }
  }
  &.sb-example {
    background-color: #d6b
  }
}