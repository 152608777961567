@import '../../util/Constant/sass-variable-list.scss';

.year-slider {
  background-color: $grey--lightest;
  box-sizing: border-box;
  height: 36px;
  padding: $padding--small-tiny 0.5%;
  position: relative;
  width: 100%;

  &.year-slider {
    margin-bottom: 4px;
    margin-top: 2px;
  }

  &__rail {
    background-color: $grey--lightest;
    position: relative;
    width: 100%;
  }

  &__button {
    background-color: $green--dark;
    color: $white;
    font-size: $font-size--medium-large;
    position: relative;
    top: 0;
    width: 5%;

    &:focus {
      background-color: $green;
      color: $black;
    }
  }

  &__ghost-button {
    background-color: $green-grey--light;
    font-size: $font-size--medium-large;
    position: absolute;
    top: 0;
    width: 5%;
    :focus {
      background-color: none;
    }
  }
}