@import '../../util/Constant/sass-variable-list.scss';

.gantt-bar-quantile-list {
  height: $gantt-bar-quantile-height;
  &__line {
    background-color: $grey--dull-dark;
    height: $gantt-bar-quantile-height;
    position: absolute;
    width: 2px;
    z-index: $step-right-forward;
  }
}
