.histogram-maker {
  width: 100%;
  position: relative;
  &__data {
    flex: 1;
    .year-slider {
      position: relative;
      top: 36px;
    }
  }
}
