.histogram-bar--sb-1  { background-color: hsl(0   80% 50%) }
.histogram-bar--sb-2  { background-color: hsl(20  80% 50%) }
.histogram-bar--sb-3  { background-color: hsl(40  80% 50%) }
.histogram-bar--sb-4  { background-color: hsl(60  80% 50%) }
.histogram-bar--sb-5  { background-color: hsl(80  80% 50%) }
.histogram-bar--sb-6  { background-color: hsl(100 80% 50%) }
.histogram-bar--sb-7  { background-color: hsl(120 80% 50%) }
.histogram-bar--sb-8  { background-color: hsl(140 80% 50%) }
.histogram-bar--sb-9  { background-color: hsl(160 80% 50%) }
.histogram-bar--sb-10 { background-color: hsl(180 80% 50%) }
.histogram-bar--sb-11 { background-color: hsl(200 80% 50%) }
.histogram-bar--sb-12 { background-color: hsl(220 80% 50%) }
.histogram-bar--sb-13 { background-color: hsl(240 80% 50%) }
.histogram-bar--sb-14 { background-color: hsl(260 80% 50%) }
.histogram-bar--sb-15 { background-color: hsl(280 80% 50%) }
.histogram-bar--sb-16 { background-color: hsl(300 80% 50%) }
.histogram-bar--sb-17 { background-color: hsl(320 80% 50%) }
.histogram-bar--sb-18 { background-color: hsl(340 80% 50%) }