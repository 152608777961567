@import '../../../../util/Constant/sass-variable-list.scss';

.biased-correlation-sim {
  width: 100%;
  justify-content: center;

  .scatter-chart {
    max-height: 80vh;
    max-width: 80vh;
  }
}
