@import '../../../../util/Constant/sass-variable-list.scss';

.pathological-event-list {
  tr {
    &.heading {
    }
    &.sure {
      background-color: $blue--lightest;
    }
    &.heading {
    }
    &.not-so-sure {
      background-color: $red--lightest;
    }
  }

  th,
  td {
    font-size: $font-size--medium-small;
    padding: $padding--tiniest $padding--small-tiny;
  }

  .heading {
    td {
      background-color: $grey--dark;
      color: $white;
      text-align: center;
    }
  }

  td {
    box-shadow: 0 0 0 1px inset $blue--dark;
    &.center {
      text-align: center;
    }
    &.num {
      text-align: right;
    }
    .list {
      width: 100%;
      display: flex;
      span {
        min-width: 22px;
        padding: $padding--tiniest;
      }
    }
    .half {
      width: 50%
    }
    .third {
      width: 33.33%
    }
    .quarter {
      width: 25%
    }
    .seventh {
      width: 14.285%
    }
  }
}