@import '../../util/Constant/sass-variable-list.scss';

.error-output {
  align-items: flex-start;
  width: 100%;
  display: flex;
  justify-content: center;
  &__text {
    background-color: $red--dark;
    color: $white;
    font-size: $font-size--medium;
    font-weight: bold;
    padding: $padding--small $padding--medium;
  }
}
