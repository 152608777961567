@import '../../util/Constant/sass-variable-list.scss';

.secondary-nav {
  flex-wrap: wrap;
  position: relative;

  & > * {
    @media (max-width: $media-switch--large) {
      margin-top: $spacing--narrow;
    }
  }
}
