@import '../../util/Constant/sass-variable-list.scss';

.menu-button {
  background-color: $menu--green;
  color: $white;
  font-size: $font-size--medium;
  font-weight: $font-weight--bold;
  width: 100%;

  &:focus,
  &:hover {
    background-color: $menu--green-hover;
    color: $black;
  }
}