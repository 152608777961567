@import '../../util/Constant/sass-variable-list.scss';

.filter-button-list {
  position: relative;
  @include row-layout();
  & > * {
    @include space-row();
  }

  @media (max-width: $media-switch--large) {
    flex-wrap: wrap;
    & > * {
      @include space-column();
    }
  }
}