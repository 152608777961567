@import '../../util/Constant/sass-variable-list.scss';

.prime-symptom-histogram {
  @include column-layout();
  width: 100%;
  align-items: center;

  & > * {
    @include space-column();
  }

  &__interaction-tools {
    @include row-layout();
    font-size: $font-size--medium;
    justify-content: space-around;
    width: 100%;
    @include self-space-column-add-border();
  }

  &__tool {
    align-items: center;
    display: flex;
    h3 {
      font-size: $font-size--medium;
    }
  }

  &__graph {
    width: 100%;
  }
  &__number-explanation {
    display: block;
    font-size: $font-size--small;
    text-align: center;
    width: 100%;
  }
  .nonSevere {
    background-color: $prime-symptom--not-severe;
  }
  .severe {
    background-color: $prime-symptom--severe;
  }
}