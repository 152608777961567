@import '../../util/Constant/sass-variable-list.scss';

.x-axis-line-number {
  position: absolute;

  &.left {
    text-align: left;
    left: 0;
  }
  &.right {
    text-align: right;
    right: 0;
  }
  &.center {
    width: 100%;
    text-align: center;

    .x-axis-line-number__num {
      background-color: $blue--light;
      color: $blue--dark;
    }
  }

  &__num {
    background-color: $blue--dark;
    color: $white;
    padding: $padding--small-tiny $padding--small;
  }
}
