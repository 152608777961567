@import '../../util/Constant/sass-variable-list.scss';

.drag-graph-point-label {
  background-color: $white;
  border: 1px solid $blue;
  border-radius: 3px;
  box-sizing: border-box;
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  padding: $padding--tiniest;
  position: relative;
  opacity: 0.6;

  &__text {
    font-size: $font-size--medium-small;
    height: 15px;
    text-align: center;
    width: 100%;
  }

  &__num {
    align-items: center;
    background-color: $white;
    border-radius: 50%;
    border: 1px solid $grey--light;
    display: flex;
    height: 24px;
    justify-content: center;
    width: 24px;
  }

  &.is-selected {
    border: 1px solid $blue--dark;
    opacity: 1.0;
  }
}