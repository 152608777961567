@import './util/Constant/sass-variable-list.scss';

:root {
  /* spacing  */
  --spacing--narrowest: #{$spacing--narrowest};
  --spacing--narrow: #{$spacing--narrow};
  --spacing--wide: #{$spacing--wide};
  --spacing--very-wide: #{$spacing--very-wide};

  /* padding */
  --padding--tiniest: #{$padding--tiniest};
  --padding--small-tiny: #{$padding--small-tiny};
  --padding--small: #{$padding--small};
  --padding--medium: #{$padding--medium};
  --padding--medium-large: #{$padding--medium-large};
  --padding--large: #{$padding--large};
  --padding--very-large: #{$padding--very-large};


  /* colours  */
  --blue--dark: #{$blue--dark};
  --blue: #{$blue};
  --blue--light: #{$blue--light};
  --blue--lightest: #{$blue--lightest};

  --red--black: #{$red--black};
  --red--dark: #{$red--dark};
  --red--medium: #{$red--medium};
  --red--dulled-light: #{$red--dulled-light};
  --red--light: #{$red--light};
  --red--very-light: #{$red--very-light};
  --red--lightest: #{$red--lightest};

  --green--dark: #{$green--dark};
  --green: #{$green};
  --green--lightest: #{$green--lightest};

  --green-grey: #{$green-grey};
  --green-grey--light: #{$green-grey--light};




  --orange--dark: #{$orange--dark};
  --orange: #{$orange};
  --orange--light: #{$orange--light};

  /* greyscale  */
  --black: #{$black};
  --grey--black: #{$grey--black};
  --grey--dark: #{$grey--dark};
  --grey--dull-dark: #{$grey--dull-dark};
  --grey--medium: #{$grey--medium};
  --grey--light: #{$grey--light};
  --grey--very-light: #{$grey--very-light};
  --grey--lightest: #{$grey--lightest};
  --grey--white: #{$grey--white};
  --white: #{$white};


  /* font size */
  --font-size--tiniest: #{$font-size--tiniest};
  --font-size--small-tiny: #{$font-size--small-tiny};
  --font-size--small: #{$font-size--small};
  --font-size--medium-small: #{$font-size--medium-small};
  --font-size--medium: #{$font-size--medium};
  --font-size--medium-large: #{$font-size--medium-large};
  --font-size--large: #{$font-size--large};
  --line-height--small: #{$line-height--small};
  --line-height--large: #{$line-height--large};

  /* font-weight */
  --font-weight--light: #{$font-weight--light};
  --font-weight--regular: #{$font-weight--regular};
  --font-weight--bold: #{$font-weight--bold};
  --font-weight--black: #{$font-weight--black};


  /* gantt */
  --gantt-bar-height: #{$gantt-bar-height};
  --gantt-bar-quantile-height: #{$gantt-bar-quantile-height};


  /* histogram */
  --histogram-bar-group-height: #{$histogram-bar-group-height};


  /* prime symptom histogram */
  --prime-symptom--not-severe: #{$prime-symptom--not-severe};
  --prime-symptom--severe: #{$prime-symptom--severe};


  /* menu */
  --menu--green: #{$menu--green};
  --menu--green-hover: #{$menu--green-hover};


  /* z-index */
  --background-guaranteed: #{$background-guaranteed};
  --step-right-back: #{$step-right-back};
  --step-back: #{$step-back};
  --neutral: #{$neutral};
  --step-forward: #{$step-forward};
  --step-right-forward: #{$step-right-forward};
  --front-guaranteed: #{$front-guaranteed};
  --menu-guaranteed: #{$menu-guaranteed};
}
