@import '../../util/Constant/sass-variable-list.scss';

.menu-item {
  display: inline-block;
  padding: $padding--medium-large $padding--large;
  white-space: nowrap;

  &:focus {
    outline-style: auto;
  }

  &--current {
    background-color: $blue--dark;
    color: $white;
  }
}
