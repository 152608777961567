@import '../../util/Constant/sass-variable-list.scss';

.outcome-summary {
  @include column-layout();
  @include self-space-column-add-border();
  align-items: center;
  font-size: $font-size--medium;
  white-space: nowrap;
  width: 100%;

  @media (min-width: $media-switch--large) {
    @include row-layout();
    justify-content: center;
  }

  & > * {
    @include space-column-add-border();
    @media (min-width: $media-switch--large) {
      @include space-row-add-border();
    }
  }

  &__percentage-wrapper {
    @include row-layout();
    align-items: center;
    & > * {
      @include space-row-add-border();
    }
    @media (min-width: $media-switch--large) {
      @include self-space-column-remove-border();
    }
  }

  &__percentage,
  &__total-list {
    @include row-layout();
    align-items: center;
    & > * {
      @include space-row();
    }

    .hide {
      display: none;
      @media (min-width: $media-switch--medium) {
        display: inline;
      }
    }
  }

  &__total {
    align-items: center;
  }

  &__total-marker {
    background-color: $black;
  }

  &__label {
    color: $white;
    padding: $padding--small-tiny $padding--small;
  }
  &__severe-ave {
    background-color:$red--dark;
  }
  &__non-severe-ave {
    background-color:$blue--dark;
  }
  &__lowest-median-marker {
    border-left: 20px solid $green;
  }

  &__unknown-count {
    background-color: $orange--light;
    color: $black;
    &.outcome-summary__unknown-count {
      padding-right: $spacing--narrow;
    }
  }

  h2 {
    box-shadow: none;
  }
}
