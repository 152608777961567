@import '../../../../util/Constant/sass-variable-list.scss';

.biased-timing-sim {
  .hide {
    .data-adjuster-button-list {
      display: none;
      @media (min-width: $media-switch--medium) {
        display: flex;
      }
    }
  }

  .histogram__caption,
  .prime-symptom-histogram__number-explanation {
    display: none;
  }

  .prime-symptom-histogram__figure {
    margin-top: -50px;
  }
}