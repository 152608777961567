@import '../../util/Constant/sass-variable-list.scss';

.scatter-chart {
  box-sizing: border-box;
  flex: 1;
  padding: $padding--very-large;
  width: 100%;
  max-width: 90vh;
  max-height: 90vh;
  &__number {
    fill: $black;
    fill-opacity: 1;
    font-size: $font-size--medium-small;
    text-align: right;
  }
  &__y-label {
    transform: rotate(-90deg);
  }
  &__outcome-severe {
    fill: $prime-symptom--severe;
    stroke: $prime-symptom--severe;
  }
  &__outcome-not-severe {
    fill: $prime-symptom--not-severe;
    stroke: $prime-symptom--not-severe;
  }
}
