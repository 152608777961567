@import '../../util/Constant/sass-variable-list.scss';

.perc-output {
  background-color:  $grey--light;
  width: 100px;
  &__current {
    background-color: $blue--dark;
    height: 20px;
  }
  &__unknown {
    background-color: $orange--light;
    height: 20px;
  }
}
