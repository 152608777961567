@import '../../util/Constant/sass-variable-list.scss';

.ui-header-bar {
  border-bottom: 1px solid $grey--black;
  padding-bottom: $spacing--narrow;
  transition: left 1s;
  width: 100%;

  &__main-button {
    left: 50%;
    margin-left: -55px;
    position: absolute;
    top: 0;
    width: 110px;
    @media (min-width: $media-switch--large) {
      display: none;
    }
  }

  &__close-x-button {
    position: absolute;
    top: -5px;
    right: 0;
    @media (min-width: $media-switch--large) {
      display: none;
    }
  }

  &__nav-and-filter {
    @include row-layout();
    justify-content: space-between;
    @media (max-width: $media-switch--large) {
      @include column-layout();
      & > * {
        @include space-column--wide-add-border();
      }
    }
  }

  &.open {
    @media (max-width: $media-switch--large) {
      box-shadow: 0 0 10px 0 $grey--dark;
      left: 0;
    }
  }

  @media (max-width: $media-switch--large) {
    background-color: $white;
    border-bottom: 1px solid $grey--black;
    border-right: 1px solid $grey--black;
    border-top: $padding--medium solid $white;
    border-left: $padding--medium solid $white;
    left: -210px;
    position: absolute;
    top: 0 - $padding--small-tiny;
    width: 200px;
    z-index: $menu-guaranteed;
  }
}
