@import '../../util/Constant/sass-variable-list.scss';

.svg {
  &__graph-list {
    @include row-layout();
    width: 100%;
    flex-wrap: wrap;

    .drag-graph {
      flex: 1;
    }

    & > * {
      margin-left: 1%;
      margin-right: 1%;
      width: 98%;
      @media (min-width: $media-switch__large) {
        margin-left: 0.5%;
        margin-right: 0.5%;
        width: 49%;
      }
    }
  }
}