@import '../../util/Constant/sass-variable-list.scss';

.world-map {
  display: flex;
  justify-content: center;
  max-width: 100%;
  height: 79vh;

  &__wrapper {
    .page-detail-wrapper__header {
      margin-bottom: 1px;
    }
  }
}